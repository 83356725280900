import "./App.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDocumentStore } from "./stores";
import Loader from "react-loader-spinner";
import ReactMarkdown from "react-markdown";
import manifestLogo from "./assets/manifestLogo.svg";
import NotFound from "./notfound";

export default function App() {
  const { document, loading, loadDocument } = useDocumentStore();
  const { slug } = useParams();

  useEffect(() => {
    loadDocument(slug);
  }, [slug]);

  if (loading) {
    return (
      <div className="page-container top">
        <div className="page">
          <Loader
            type="Oval"
            color="#dee3eb"
            height={80}
            width={80}
            style={{ alignSelf: "center" }}
          />
        </div>
      </div>
    );
  } else if (!document) {
    return <NotFound />;
  } else {
    return (
      <>
        <div className="content">
          <img
            src={manifestLogo}
            alt="Manifest Logo"
            style={{ width: 180, marginBottom: 50, alignSelf: "flex-start" }}
          />
          <ReactMarkdown linkTarget="_blank">{document}</ReactMarkdown>
        </div>
      </>
    );
  }
}
