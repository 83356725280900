export default function NotFound() {
  return (
    <div className="page-container centered">
      <div className="page">
        <h1>Oops!</h1>
        <p>Error 404 — Page not found.</p>
      </div>
    </div>
  );
}
