import create from "zustand";
import axios from "axios";

export default create((set, get) => ({
  loading: false,
  loadingDocumentList: false,
  document: null,
  documentList: [],
  documentClick: (slug) => {
    return (e) => {
      e.preventDefault();
      const { loadDocument } = get();
      loadDocument(slug);
    };
  },
  loadDocument: async (slug) => {
    const contentfulLocale = "en-US";
    const query = `
        {
          documentCollection(where: {slug: "${slug}"}) {
            items {
              body (locale: "${contentfulLocale}")
			        body2 (locale: "${contentfulLocale}")
			        body3 (locale: "${contentfulLocale}")
			        body4 (locale: "${contentfulLocale}")
			        body5 (locale: "${contentfulLocale}")
			        body6 (locale: "${contentfulLocale}")
			        body7 (locale: "${contentfulLocale}")
			        body8 (locale: "${contentfulLocale}")
            }
          }
        }
        `;

    try {
      set({ loading: true });

      const {
        data: {
          data: {
            documentCollection: { items },
          },
        },
      } = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer Ue56460ccUWnR41JzQ7lusbvvBXb1XgggIyyoDujWgY",
        },
        url: `https://graphql.contentful.com/content/v1/spaces/yxxefa9lxddz/`,
        data: JSON.stringify({ query }),
      });

      if (items.length > 0) {
        const { body, body2, body3, body4, body5, body6, body7, body8 } =
          items[0];
        const document = [
          body,
          body2,
          body3,
          body4,
          body5,
          body6,
          body7,
          body8,
        ].join("\n");
        set({ document, loading: false });
      } else {
        set({ loading: false });
      }
    } catch (e) {
      console.log(e);
    }
  },
  loadDocumentList: async () => {
    const query = `
    {
      documentCollection(where: {showInWebListing: true} order: [title_ASC]) {
        items {
          title
          slug
        }
      }
    }
    `;

    try {
      set({ loadingDocumentList: true });

      const {
        data: {
          data: {
            documentCollection: { items },
          },
        },
      } = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer Ue56460ccUWnR41JzQ7lusbvvBXb1XgggIyyoDujWgY",
        },
        url: `https://graphql.contentful.com/content/v1/spaces/yxxefa9lxddz/`,
        data: JSON.stringify({ query }),
      });

      set({ documentList: items, loadingDocumentList: false });
    } catch (e) {
      console.log(e);
    }
  },
  dismiss: () => {
    set({ document: null, loading: false });
  },
  loadMarkdown: (document) => {
    set({ document, loading: false });
  },
}));
