import "./App.scss";
import { useEffect } from "react";
import { useDocumentStore } from "./stores";
import Loader from "react-loader-spinner";
import manifestLogo from "./assets/manifestLogo.svg";

export default function Listing() {
  const { documentList, loadingDocumentList, loadDocumentList } =
    useDocumentStore();

  useEffect(() => {
    loadDocumentList();
  }, []);

  if (loadingDocumentList) {
    return (
      <div className="page-container top">
        <div className="page">
          <Loader
            type="Oval"
            color="#dee3eb"
            height={80}
            width={80}
            style={{ alignSelf: "center" }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="content">
          <img
            src={manifestLogo}
            alt="Manifest Logo"
            style={{ width: 180, marginBottom: 50, alignSelf: "flex-start" }}
          />
          <h1>Legal Notices</h1>
          <blockquote style={{ paddingBottom: 40 }}>
            {documentList.map((document) => (
              <h3>
                <a href={document.slug}>{document.title}</a>
              </h3>
            ))}
          </blockquote>
        </div>
      </>
    );
  }
}
