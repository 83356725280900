import "./index.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./App";
import Listing from "./Listing";
import NotFound from "./notfound";

const theme = createTheme({
  palette: {
    primary: {
      main: "#657C9D",
    },
    secondary: {
      main: "#8DA4C4",
    },
    error: {
      main: "#fe6c6c",
    },
    warning: {
      main: "#FFAC50",
    },
    success: {
      main: "#7AB53F",
    },
    info: {
      main: "#02B8D8",
    },
  },
});

const rootElement = document.getElementById("root");
render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path={"/"} element={<Listing />} />
        <Route path={"/:slug"} element={<App />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  rootElement
);
